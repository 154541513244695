import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import RolexCategoryBtns from '../../components/preowned/RolexCategoryBtns'

// markup
const RolexGMT = () => {
  const data = useStaticQuery(
    graphql`
      query queryRolexGMT {
        products: allStrapiProduct(
          filter: { brand: { eq: "Rolex" }, model: { regex: "/GMT-Master/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Rolex GMT Watches for Sale'}
      canonical={'/fine-watches/rolex/rolex-gmt-master/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Rolex GMT Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/rolex/rolex-gmt-master/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-rolex-gmt-header.png"
                alt="Pre-Owned Certified Used Rolex GMT Master Watches Header"
                aria-label="Used Rolex GMT Master Watch Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              ROLEX GMT-MASTER: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED ROLEX WATCHES
            </h1>
            <h2>BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED ROLEX GMT-MASTER WATCHES</h2>
            <p>
              <br />
              <br />
              Anyone keeping up with the luxury swiss watch market lately can agree, Rolex has
              skyrocketed to levels unimaginable. It has become nearly impossible to run into a
              Rolex Pepsi, Rolex Batman, Rolex Batgirl, Rolex Rootbeer, Rolex Starbucks, Rolex Coke;
              or generally any Rolex GMT-Master for purchase!
              <br />
              <br />
              When Rolex Boutiques and Authorized Rolex dealers tell consumers they have to wait
              over 15 months for the Rolex GMT Master or Rolex GMT Master II they desire, consumers
              begin to look elsewhere. That is where Gray and Sons Jewelers comes into play. With
              over 42 years of experience in the industry of fine swiss watches, we are the #1 used
              and pre-owned Rolex seller in all of South Florida. Here at Gray and Sons Jewelers, we
              buy, sell, and trade all Rolex sports models, and we are also a certified repair
              center for Rolex watches. We have six Swiss-trained watchmakers here, in-house, with
              over 150 years of combined watchmaking experience. We have been repairing Rolex
              wristwatches along with other fine swiss watch brands since 1980. Whether a timepiece
              is in need of polish, detail, refinish, restoration, or installation/removal of any
              links, we can help! <a href="/">Grayandsons.com</a>
              will be there for Buying Rolex GMT Master, Selling Rolex GMT Master and Repairing
              yourRolex GMT Master II 2.
              <br />
              <br />
              We carry Rolex GMT-Master 40mm and Rolex GMT-Master II watches in stainless steel,
              two-tone, 18k gold, and with Platinum on oyster bracelets and jubilee bracelets. We
              have the exact pre-owned certified authentic Rolex watch model you are looking for. We
              also carry some unused Rolex timepieces that have never been worn! Browse through our
              Rolex Day-Date 36mm, Rolex SeaDweller 44mm, Rolex Submariner 40mm, Rolex Datejust 41,
              Rolex Date, and many more Rolex models on our website www.grayandsons.com to find the
              exact Rolex you need!
              <br />
              <br />
              We guarantee every Rolex Submariner watch to be certified authentic, so much so we
              give our clients a 24-month warranty on all pre-owned Rolex watches from bought at our
              Bal-Harbour showroom. In our store we carry all items seen on our website, clients can
              visit our store to try on Rolex watches! Our used Rolex watches can be found in our
              store in Surfside, Florida near Miami Beach. Countless people near me say, “I want to
              sell my Rolex GMT Master and GMT-Master II 2 for cash. Gray and Sons and
              sellusyourjewelry.com will buy your Rolex GMT Master for cash. Talk to a real person
              on our website who can help you buy, sell, trade, repair used and pre owned watches.{' '}
              <a href="/chat-with-rich">CHAT NOW </a> with Viktoria or Rich? Vika and Rich are both
              real specialists ready to make a deal now.
              <br />
              <br />
              These days people say, “I want to sell my Rolex GMT-Master watch for cash.”
              <a href="/">www.grayandsons.com </a> and{' '}
              <a href="https://sellusyourjewelry.com">www.sellusyourjewelry.com </a>
              will buy your watch for cash. Gray and Sons is the #1 place to repair, buy, sell and
              trade Rolex GMT-Master watches.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/rolex/rolex-gmt-master/">
                <button>SHOP ROLEX GMT-MASTER WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK ROLEX GMT-MASTER WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <section>
        <h2 className="text-center py-10">EXPLORE MORE GREAT WATCH STORIES FROM GRAY &amp; SONS</h2>
        <RolexCategoryBtns />
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/watch?v=1SnLd62tw_I'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default RolexGMT
